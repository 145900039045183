module.exports = new Promise(resolve => {
  // Used to generate random id as method of simple cache busting for remote entry
  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, () =>
      Math.floor(Math.random() * 16).toString(16),
    );
  }

  // Random version ensures remote entry is never cached
  let remoteUrlWithVersion = '/cockpit/remoteEntry.js?v=' + uuidv4()

  // get dynamic remote url
  // or standalone remote url
  const script = document.createElement('script')
  script.src = remoteUrlWithVersion

  script.onload = () => {
    // the injected script has loaded and is available on window
    // we can now resolve this Promise
    const proxy = {
      get: (request) => window.cockpit.get(request),
      init: (arg) => {
        try {
          return window.cockpit.init(arg)
        } catch(e) {
          console.log('remote container already initialized')
        }
      }
    }
    resolve(proxy)
  }
  document.head.appendChild(script);
})
;